import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import config from '../global/config'
import styles from './style.css';

const recordFeedbackUrl = "/command/survey"
const SurveyFeedback = (props) => {
    const [completedFeedbackQuestionary, setCompletedFeedbackQuestionary] = useState(false);
    const {search} = useLocation();
    const queryString = useMemo(() => new URLSearchParams(search), [search]);

    const [feedbackResponse, setFeedbackResponse] = useState(null);

    useEffect(() => {
        axios.post(config.baseUrl + recordFeedbackUrl, {
            numberOfStars: queryString.get("numberOfStars"),
            surveyCode: queryString.get("surveyCode"),
        })
            .then((response) => {
                setFeedbackResponse(response.data);
            })
            .catch((error) => {
            });
      }, [queryString.get("numberOfStars"), queryString.get("surveyCode")]);
    
    if(!feedbackResponse){
        return (<></>);
    }
    let message = "Thank you for providing your feedback on your recent training experience. We strive to provide the best service, and will review your feedback for opportunities for improvement.";
    let isImageVisible = true;
    if(feedbackResponse.isAlreadyProvided){
        // link has been opened before
        isImageVisible = false;
        message = "You have already provided feedback on this survey.";
    }

    if(!completedFeedbackQuestionary && feedbackResponse.notRecoreded && feedbackResponse.questions){
        const completeSurveyFeedback = ()=>{
            axios.post(config.baseUrl + recordFeedbackUrl, {
                numberOfStars: queryString.get("numberOfStars"),
                surveyCode: queryString.get("surveyCode"),
                answers: feedbackResponse.questions.map(q => {
                    let answer = document.querySelector(`input[name="answer${q.id}"]:checked`);
                    if(!answer){
                        answer = document.querySelector(`textarea[name="answer${q.id}"]`);
                    }
                    return {
                        questionId: q.id,
                        answer: answer.value
                    }
                })
            })
            .then((response) => {
                setCompletedFeedbackQuestionary(true);
            })
            .catch((error) => {
            });
        };
        var questtions = feedbackResponse.questions.map(q => {
            let answersBlock = q.answers.map(a => {
                return <div className="form-check">
                    <input class="form-check-input" type="radio" name={"answer"+q.id}  value={a.id}/>
                    <label class="form-check-label" for={"answer"+q.id}>{a.text}</label>
                </div>
            });
            if(q.questionType == 2){
                answersBlock = (<div>
                    <textarea  className="form-control"  maxLength="500" name={"answer"+q.id}></textarea>
                </div>);
            }
            return <div>
                <h4>{q.questionText}</h4>
                {answersBlock}
                {q.questionType == 1 ? <hr/> : null}
            </div>
           });
        return (<>
            <section className="body_section">
                <div className="container">
                    <div className="col-10">
                        {questtions}
                        <div className="wrap text-center complete-survey-block">
                            <a onClick={completeSurveyFeedback} className="more">Complete Survey Feedback</a>
                        </div>
                    </div>

                </div>
            </section>
            
        </>)
    }

    return (
        <>
            <section className="body_section">
                <div className="container">
                    <div className="col-6 col-4">
                        { isImageVisible && <img src="/thank_you1.svg" alt="" className="three" />}
                    </div>
                    <div className="col-6 col-8">
                        <div className="wrap">
                            <p>{message}</p>
                        </div>
                    </div>

                    
                </div>
            </section>
        </>
    );
};

export default SurveyFeedback;